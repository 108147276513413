<template>
  <v-form
    ref="form"
    class="pa-2"
    @submit.prevent="onSave"
  >

    <text-field
      v-model="model.value"
      :label="$t('inputs.name.label')"
      :rules="rules.value"
      prefix="#"
    />

  </v-form>
</template>

<script>
import TextField from '@/components/form/TextField';
import { required } from '@/utils/rules';

export default {
  components: {
    TextField
  },
  props: {
    value: Object,
    save: Function
  },
  data() {
    return {
      model: this.value || {}
    }
  },
  computed: {
    rules() {
      return {
        value: [
          required( this.$t('inputs.name.rules.required'))
        ]
      };
    }
  },
  watch: {
    value( model ) {
      this.model = model || {};
    }
  },
  methods: {
    onSave() {
      this.$emit( 'input', this.model );
      this.save && this.$nextTick( this.save );
    },
    validate() {
      return this.$refs.form && this.$refs.form.validate();
    },
    resetValidation() {
      return this.$refs.form && this.$refs.form.resetValidation();
    }
  }
}
</script>
