<template>
  <table-view
    ref="table"
    api="tag"
    v-model="dialog"
    :filters="filters"
    :headers="headers"
    :popup="popup"
    :save="save"
    :edit="edit"
    @click:item="edit( $event.item.item )"
    selectable
  >
  <template v-slot:item.value.picture="{ value }">

    <d-image
      :value="value"
      :width="imageDimension"
      :height="imageDimension"
      :color="value ? 'transparent' : undefined"
      item-image="thumbnailUrl"
      hide-placeholder
      outlined
      contain
    />

  </template>
  <template v-slot:item.value.customer="{ value }">

    <router-link v-if="value" :to="`/customer?id=${value.id}`">
      {{ value.name }}
    </router-link>

  </template>
  <template v-slot:item.value.status="{ value }">

    <v-icon :color="value === 1 ? 'success' : 'error'">
      {{
        value === 1
          ? 'mdi-checkbox-marked-circle'
          : 'mdi-close-circle'
      }}
    </v-icon>

  </template>
  <template v-slot:form>

    <offer-form
      ref="form"
      v-model="model"
      :save="save"
    />

  </template>
  <template v-slot:footer>

    <v-spacer/>

    <btn
      class="mr-2"
      color="primary"
      :loading="exporting"
      @click.stop="exportData"
    >
      {{ $t('btn.export') }}
    </btn>

    <btn @click.stop="edit()">
      {{ $t('tag.btn.new') }}
    </btn>

  </template>

  </table-view>
</template>

<script>
import TableView from '@/components/TableView';
import DImage from '@/components/Image';
import OfferForm from './Form';
import { ICONS } from '@/utils/constants';

export default {
  name: 'Offers',
  auth: true,
  head: vm => ({ title: vm.$i18n.t('tag.title') }),
  layout: 'admin',
  layoutProps: ({ i18n }) => ({
    title: i18n.t('tag.title'),
    icon: ICONS.tag
  }),
  components: { TableView, DImage, OfferForm },
  data: () => ({
    model: {},
    dialog: false,
    exporting: false,
    filters: [],
    popup: {}
  }),
  computed: {
    headers() {
      return [
        {
          key: 'value',
          mobile: true,
          text: this.$t('inputs.name.label'),
          display: v => v ? `#${v}` : '',
          filter: { prefix: '#' }
        }
      ];
    }
  },
  methods: {
    edit( item ) {

      this.dialog = true;
      this.popup = {
        ...this.popup,
        title: this.$t(`tag.btn.${ item ? 'edit': 'new' }`)
      };

      if ( item ) {
        this.$router.replace({
          path: this.$route.path,
          params: this.$route.params,
          query: { ...this.$route.query, id: item.id }
        }).catch(() => {});
      }

      this.$nextTick(() => {
        this.$refs.form.resetValidation();
        this.model = {
          id: -1,
          status: 1,
          ...item
        };
      });
    },
    exportData() {
      if ( this.exporting ) return;
      this.exporting = true;
      this.$refs.table.exportData().finally(() => {
        this.exporting = false;
      });
    },
    sanitize( model ) {
      return {
        ...model
      };
    },
    save() {
      if ( this.$refs.form.validate()) {
        this.dialog = false;
        this.model = this.$refs.form.model;
        this.$store
          .dispatch('api/tag/set', this.sanitize( this.model ))
          .then(() => this.$refs.table.refresh())
          .then(() => {
            this.$store.dispatch( 'app/success', {
              message: this.$t('alerts.save')
            });
          }).catch( err => {
            this.$store.dispatch( 'app/error', {
              message: err.message
            });
          });
      }
    }
  }
}
</script>
